import React from "react";
import Navbar from "./components/navbar";
import colors from "./components/colors";
import Contact from "./components/contact";

function App() {
  return (
    <div className={`h-screen w-screen flex bg-background`}>
      <Navbar />
    </div>
  );
}

export default App;
