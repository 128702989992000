import { useState } from "react";

function Contact() {
  return (
    <div className="card card-compact bg-primary h-44 w-[80%] sm:w-[70%] md:w-[40%] lg:w-[30%] xl:w-[25%]">
      <div className="card-body">
        <h2 className="card-title text-slate-100">Hemen Bize Ulasin</h2>
        <text className="font-bold text-slate-300">
          Beraber calismamiza cok az kaldi
        </text>
        <p className="font-semibold text-slate-300 ">
          Email: info@builderyazilim.com
        </p>
        <div className="card-actions justify-center">
          <button className="btn btn-primary">Mail Gonder</button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
