import { useState } from "react";
import Contact from "./contact";

function Navbar() {
  const [isContactOpen, setIsContactOpen] = useState(false);

  const toggleContact = () => {
    setIsContactOpen(!isContactOpen);
  };
  return (
    <div
      className={`navbar bg-primary shadow-2xl lg:w-[70%] md:w-[85%] w-[90%] h-12 rounded-xl mx-auto mt-2`}
    >
      <div className="navbar-start hidden lg:flex">
        <button className="btn btn-ghost text-xl">Builder Yazilim</button>
      </div>
      <div className="navbar-center">
        <div>
          <button className="btn btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-home"
            >
              <path d="M3 9.5L12 2l9 7.5v10.5c0 1.1-.9 2-2 2h-4c-1.1 0-2-.9-2-2v-6h-4v6c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V9.5z"></path>
            </svg>
            <div className="hidden sm:flex">Anasayfa</div>
          </button>
        </div>
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h7"
              />
            </svg>
            <div className="hidden sm:flex">Hizmetlerimiz</div>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-md dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
          >
            <li>
              <button>Website</button>
            </li>
            <li>
              <button>E-Mail</button>
            </li>
          </ul>
        </div>
        <div>
          <button className="btn btn-ghost" title="Hakkimizda">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              className="feather feather-info"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
            <div className="hidden sm:flex">Hakkimizda</div>
          </button>
        </div>
      </div>
      <div className="navbar-end">
        <button className="btn btn-ghost text-accent" onClick={toggleContact}>
          Bize Ulasin
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="feather feather-mail"
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>
        </button>
        {isContactOpen && (
          <div
            className="fixed z-1 inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                toggleContact();
              }
            }}
          >
            <Contact />
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
